
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-slidedown/lib/slidedown.css'
import 'remixicon/fonts/remixicon.css'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import 'utils/setupYupLocale'
import '../assets/fonts/apercu.scss'
import '../styles/colors.scss'
import '../styles/units.scss'
import '../styles/app.scss'
import '../styles/global.scss'
import '../styles/reset.scss'

import { usePersistLocaleCookie } from 'hooks/usePersistedLocale'
import Cookies from 'js-cookie'
import { AppProps } from 'next/app'
import Head from 'next/head'
import CookieConsent from 'react-cookie-consent'
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { FacebookAnalytics } from 'components/analytics/FacebookAnalytics'
import { GoogleAnalytics } from 'components/analytics/GoogleAnalytics'
import { Hotjar } from 'components/analytics/Hotjar'
import { ToastProvider } from 'context/ToastContext'
import { UserProvider } from 'context/UserContext'
import { LoadingProvider } from 'context/LoadingContext'
import { ChatBot } from 'components/ChatBot'
import { GoogleAd } from 'components/analytics/GoogleAd/GoogleAd'
import { Typography, TypographyType } from '../components/DataDisplay/Typography/Typography'
import { Link } from '../components/Link/Link'
import { RepositoriesProvider } from '../context/RepositoriesContext'
import { LocaleTypes, TranslationProvider } from '../context/TranslationContext'
import { spanish } from '../i18n/locales/spanish'
import { RepositoriesFactory } from '../repositories/RepositoriesFactory'
import { Routes } from '../services/router/Routes'

const locales = { [LocaleTypes.ES_ES]: spanish }
const queryClient = new QueryClient()

function MyApp({ Component, pageProps }: AppProps) {
  usePersistLocaleCookie()

  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
  }

  return (
    <ToastProvider>
      <LoadingProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <Head>
              <script async src="https://www.googletagmanager.com/gtag/js?id=GTM-KN8496S" />
              <script
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', 'GTM-KN8496S');`,
                }}
              />
              <script
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: `history.scrollRestoration = "manual";`,
                }}
              />
              <title>Libeen SmartHousing: El nuevo alquiler con opción a compra</title>
              <meta
                name="description"
                content="Descubre el SmartHousing: El nuevo alquiler con opción a compra que está revolucionando el mercado"
              />
            </Head>
            <UserProvider initialDevice={pageProps.deviceType}>
              <TranslationProvider fallback={spanish} locales={locales}>
                <RepositoriesProvider repositories={RepositoriesFactory.getInstance(Cookies.get('accessToken'))}>
                  <Component {...pageProps} />

                  <CookieConsent
                    location="bottom"
                    buttonText="acepto"
                    enableDeclineButton
                    declineButtonText="rechazo"
                    cookieName="acceptanceCookieLibeen"
                    buttonStyle={{
                      background: '#42576b',
                      color: '#fff',
                      fontSize: '16px',
                      padding: '16px 16px',
                      borderRadius: '4px',
                      letterSpacing: '1.7px',
                      textTransform: 'uppercase',
                      fontFamily: 'Apercu, sans-serif',
                      fontWeight: '200',
                      alignSelf: 'center',
                      width: '40%',
                    }}
                    declineButtonStyle={{
                      background: '#a2a2a9',
                      color: '#fff',
                      fontSize: '16px',
                      padding: '16px 16px',
                      borderRadius: '4px',
                      letterSpacing: '1.7px',
                      textTransform: 'uppercase',
                      fontFamily: 'Apercu, sans-serif',
                      fontWeight: '200',
                      alignSelf: 'center',
                      width: '40%',
                    }}
                    buttonWrapperClasses="cookie-wrapper-button"
                    style={{
                      background: '#fff',
                      boxShadow: '0 4px 4px 3.5px var(--grey-500)',
                      width: '100vw',
                    }}
                    onAccept={() => (
                      <script
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                          __html: `fbq('consent', 'grant');`,
                        }}
                      />
                    )}
                    onDecline={() => (
                      <script
                        /* eslint-disable-next-line react/no-danger */
                        dangerouslySetInnerHTML={{
                          __html: `fbq('consent', 'revoke');`,
                        }}
                      />
                    )}
                    expires={150}
                  >
                    <Typography>
                      En esta web utilizamos cookies para mejorar la calidad de nuestros servicios y su experiencia de navegación.{' '}
                    </Typography>
                    <Typography style={{ paddingTop: '8px' }} typographyType={TypographyType.FOOTNOTE}>
                      Podrá encontrar más información en nuestra{' '}
                      <Link className="cookie-link" route={Routes.cookies()}>
                        {' '}
                        Política de Cookies
                      </Link>
                    </Typography>
                  </CookieConsent>
                  <img alt="" src="https://tracker.metricool.com/c3po.jpg?hash=7b00c9f6907f5ac7e7abf316601dafba" />
                </RepositoriesProvider>
              </TranslationProvider>
            </UserProvider>
            <ReactQueryDevtools />
            <GoogleAnalytics />
            <GoogleAd />
            <Hotjar />
            <FacebookAnalytics />
            <ChatBot />
          </Hydrate>
        </QueryClientProvider>
      </LoadingProvider>
    </ToastProvider>
  )
}

const __Page_Next_Translate__ = MyApp


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: true,
// @ts-ignore
      
// @ts-ignore
    });
// @ts-ignore
  