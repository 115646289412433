import classNames from 'classnames'
import React, { ComponentPropsWithRef, FC } from 'react'

import { Shower } from './custom-icons/Shower'
import styles from './Icon.module.scss'

export enum IconColor {
  RED,
  BLUE,
  BLUE_LIGHT,
  GREY,
  GREY_LIGHT,
  BURGUNDY,
  WHITE,
  BLACK,
  GREEN,
  YELLOW,
}

export enum IconName {
  CALENDAR = 'calendar',
  CALENDAR2 = 'calendar-2',
  HAND_HEART = 'hand-heart',
  HOTEL_BED = 'hotel-bed',
  TWITTER = 'twitter',
  WHATSAPP = 'whatsapp',
  EURO = 'money-euro',
  PRICE_TAG = 'price-tag-3',
  COMPASS = 'compass',
  SHAPE = 'shape',
  COMPASSES = 'compasses-2',
  BED = 'hotel-bed',
  SHOWER = 'shower',
  BUILDING = 'building-4',
  HEART = 'heart',
  FACEBOOK = 'facebook',
  YOUTUBE = 'youtube',
  MESSENGER = 'messenger',
  INSTAGRAM = 'instagram',
  PET = 'bear-smile',
  NATURE = 'landscape',
  NIGHT = 'moon',
  SUNNY = 'sun',
  HAPPY = 'emotion-happy',
  DISAPPOINTMENT = 'emotion-normal',
  DISCUSS = 'discuss',
  MAP = 'map-pin',
  MAP2 = 'map-2',
  RESTAURANT = 'restaurant',
  ZEN = 'ancient-pavilion',
  AIRPORT = 'flight-takeoff',
  SPORT = 'riding',
  LUXURY = 'vip-diamond',
  FAMILY = 'parent',
  STORE = 'store-2',
  LEFT_ARROW = 'arrow-left-s',
  RIGHT_ARROW = 'arrow-right-s',
  DOWN_ARROW = 'arrow-down-s',
  COMMUNITY = 'community',
  FULLSCREEN = 'fullscreen',
  PLUS = 'add',
  MINUS = 'subtract',
  BOOKMARK = 'bookmark',
  CHECK = 'check',
  ARROW_DROP_UP = 'arrow-drop-up',
  ARROW_DROP_DOWN = 'arrow-drop-down',
  ARROW_UP_DOWN = 'arrow-up-down',
  KEY = 'key-2',
  STOCK = 'stock',
  PERCENT = 'percent',
  FUND = 'funds',
  MEDAL = 'award',
  TRANSPORT = 'subway',
  BIKE = 'riding',
  PARK = 'leaf',
  DELETE_BIN = 'delete-bin',
  WATCH = 'eye',
  SAVE = 'save',
  EYE = 'eye',
  EYE_OFF = 'eye-off',
  EYE_CLOSE = 'eye-close',
  ERROR_CIRCLE = 'indeterminate-circle',
  IMAGE_ADD = 'image-add',
  IMAGE = 'image',
  OPTIONS = 'more',
  LOGOUT = 'logout-box-r',
  DRAG_MOVE = 'drag-move-2',
  HOME = 'home-smile-2',
  HOME5 = 'home-5',
  RESTART = 'restart',
  FILE = 'file-list-3',
  FILE_LIST = 'file-list-2',
  FILE_COPY = 'file-copy',
  SHOPPING_BAG = 'shopping-bag',
  CLOSE = 'close',
  ROCKET = 'rocket-2',
  MAIL = 'mail',
  MAILCHECK = 'mail-check',
  MAILUNREAD = 'mail-unread',
  LIST_UNORDERED = 'list-unordered',
  LOADER = 'loader-4',
  LAUGH = 'emotion-laugh',
  EMOTION = 'emotion',
  EQUALIZER = 'equalizer',
  TOOLS = 'tools',
  ACCOUNT_CIRCLE = 'account-circle',
  LINK = 'link',
  PENCIL = 'pencil',
  TIME = 'time',
  CLOUD = 'cloud',
  SMARTPHONE = 'smartphone',
  CHAT_SMILE = 'chat-smile-2',
  MENU = 'menu',
  SEARCH = 'search',
  NEUTRAL_EMOTION = 'emotion-normal',
  INFO = 'information',
  NOTIFICATION = 'notification-3',
  WARNING = 'alert',
  LINKEDIN = 'linkedin',
  USER_FOLLOW = 'user-follow',
  PARKING = 'parking',
  FIRE = 'fire',
  SWAP = 'swap',
  DASHBOARD_2 = 'dashboard-2',
  PLANT = 'plant',
  BASKETBALL = 'basketball',
  LIFEBUOY = 'lifebuoy',
  USER = 'user',
  USER_ADD = 'user-add',
  USER6 = 'user-6',
  USER2 = 'user-2',
  STAR = 'star',
  PLAY_MINI = 'play-mini',
  CAMERA_2 = 'camera-2',
  GLOBAL = 'global',
  PHONE = 'phone',
  ZOOM_IN = 'zoom-in',
  BRIEFCASE_2 = 'briefcase-2',
  BANK_CARD = 'bank-card',
  CHECKBOX_BLANK_CIRCLE = 'checkbox-blank-circle',
  CHECKBOX_CIRCLE = 'checkbox-circle',
  ALERT = 'alert',
  BRING_TO_FRONT = 'bring-to-front',
  SEND_TO_BACK = 'send-to-back',
  LIST_CHECK = 'list-check-2',
  PENCIL_RULER = 'pencil-ruler-2',
  ERROR_WARNING = 'error-warning',
  HAND_COIN = 'hand-coin',
  WALLET = 'wallet-3',
  TIK_TOK = 'tiktok',
  SAD = 'emotion-sad',
  FILE_EDIT = 'file-edit',
}

export enum IconType {
  FILL = 'fill',
  LINE = 'line',
  CIRCLE_LINE = 'circle-line',
  NONE = 'none',
  ONLY_NAME = 'only-name',
}

const iconTypeToString: { [key in IconName]: string } = {
  [IconName.CALENDAR]: IconType.LINE,
  [IconName.CALENDAR2]: IconType.LINE,
  [IconName.HAND_HEART]: IconType.LINE,
  [IconName.HOTEL_BED]: IconType.FILL,
  [IconName.TWITTER]: IconType.FILL,
  [IconName.WHATSAPP]: IconType.FILL,
  [IconName.EURO]: IconType.CIRCLE_LINE,
  [IconName.PRICE_TAG]: IconType.FILL,
  [IconName.COMPASS]: IconType.LINE,
  [IconName.SHAPE]: IconType.LINE,
  [IconName.COMPASSES]: IconType.LINE,
  [IconName.BED]: IconType.FILL,
  [IconName.SHOWER]: IconType.NONE,
  [IconName.BUILDING]: IconType.LINE,
  [IconName.HEART]: IconType.LINE,
  [IconName.FACEBOOK]: IconType.FILL,
  [IconName.YOUTUBE]: IconType.FILL,
  [IconName.MESSENGER]: IconType.FILL,
  [IconName.INSTAGRAM]: IconType.FILL,
  [IconName.PET]: IconType.FILL,
  [IconName.NATURE]: IconType.LINE,
  [IconName.NIGHT]: IconType.FILL,
  [IconName.SUNNY]: IconType.LINE,
  [IconName.HAPPY]: IconType.LINE,
  [IconName.DISAPPOINTMENT]: IconType.LINE,
  [IconName.DISCUSS]: IconType.LINE,
  [IconName.MAP]: IconType.LINE,
  [IconName.MAP2]: IconType.LINE,
  [IconName.RESTAURANT]: IconType.FILL,
  [IconName.ZEN]: IconType.FILL,
  [IconName.AIRPORT]: IconType.LINE,
  [IconName.SPORT]: IconType.LINE,
  [IconName.LIST_UNORDERED]: IconType.ONLY_NAME,
  [IconName.LUXURY]: IconType.LINE,
  [IconName.FAMILY]: IconType.LINE,
  [IconName.STORE]: IconType.LINE,
  [IconName.LEFT_ARROW]: IconType.LINE,
  [IconName.RIGHT_ARROW]: IconType.LINE,
  [IconName.COMMUNITY]: IconType.LINE,
  [IconName.FULLSCREEN]: IconType.LINE,
  [IconName.PLUS]: IconType.LINE,
  [IconName.MINUS]: IconType.LINE,
  [IconName.BOOKMARK]: IconType.LINE,
  [IconName.CHECK]: IconType.LINE,
  [IconName.ARROW_DROP_UP]: IconType.LINE,
  [IconName.ARROW_DROP_DOWN]: IconType.LINE,
  [IconName.ARROW_UP_DOWN]: IconType.LINE,
  [IconName.KEY]: IconType.LINE,
  [IconName.STOCK]: IconType.LINE,
  [IconName.PERCENT]: IconType.FILL,
  [IconName.FUND]: IconType.LINE,
  [IconName.MEDAL]: IconType.FILL,
  [IconName.TRANSPORT]: IconType.LINE,
  [IconName.BIKE]: IconType.LINE,
  [IconName.PARK]: IconType.FILL,
  [IconName.DOWN_ARROW]: IconType.LINE,
  [IconName.DELETE_BIN]: IconType.LINE,
  [IconName.WATCH]: IconType.LINE,
  [IconName.SAVE]: IconType.LINE,
  [IconName.EYE]: IconType.LINE,
  [IconName.EYE_OFF]: IconType.LINE,
  [IconName.EYE_CLOSE]: IconType.LINE,
  [IconName.ERROR_CIRCLE]: IconType.FILL,
  [IconName.IMAGE_ADD]: IconType.FILL,
  [IconName.IMAGE]: IconType.FILL,
  [IconName.OPTIONS]: IconType.FILL,
  [IconName.LOGOUT]: IconType.LINE,
  [IconName.DRAG_MOVE]: IconType.LINE,
  [IconName.HOME]: IconType.LINE,
  [IconName.HOME5]: IconType.LINE,
  [IconName.RESTART]: IconType.LINE,
  [IconName.FILE]: IconType.LINE,
  [IconName.FILE_LIST]: IconType.LINE,
  [IconName.FILE_COPY]: IconType.LINE,
  [IconName.SHOPPING_BAG]: IconType.LINE,
  [IconName.CLOSE]: IconType.LINE,
  [IconName.ROCKET]: IconType.LINE,
  [IconName.MAIL]: IconType.LINE,
  [IconName.MAILCHECK]: IconType.LINE,
  [IconName.MAILUNREAD]: IconType.LINE,
  [IconName.LOADER]: IconType.LINE,
  [IconName.LAUGH]: IconType.LINE,
  [IconName.EQUALIZER]: IconType.FILL,
  [IconName.TOOLS]: IconType.LINE,
  [IconName.ACCOUNT_CIRCLE]: IconType.LINE,
  [IconName.PENCIL]: IconType.LINE,
  [IconName.LINK]: IconType.ONLY_NAME,
  [IconName.TIME]: IconType.LINE,
  [IconName.CLOUD]: IconType.LINE,
  [IconName.EMOTION]: IconType.LINE,
  [IconName.SMARTPHONE]: IconType.LINE,
  [IconName.CHAT_SMILE]: IconType.LINE,
  [IconName.MENU]: IconType.LINE,
  [IconName.SEARCH]: IconType.LINE,
  [IconName.NEUTRAL_EMOTION]: IconType.LINE,
  [IconName.INFO]: IconType.LINE,
  [IconName.NOTIFICATION]: IconType.LINE,
  [IconName.WARNING]: IconType.LINE,
  [IconName.LINKEDIN]: IconType.FILL,
  [IconName.USER_FOLLOW]: IconType.LINE,
  [IconName.PARKING]: IconType.FILL,
  [IconName.FIRE]: IconType.LINE,
  [IconName.SWAP]: IconType.LINE,
  [IconName.DASHBOARD_2]: IconType.LINE,
  [IconName.PLANT]: IconType.LINE,
  [IconName.BASKETBALL]: IconType.LINE,
  [IconName.LIFEBUOY]: IconType.LINE,
  [IconName.USER_ADD]: IconType.LINE,
  [IconName.USER]: IconType.LINE,
  [IconName.USER6]: IconType.LINE,
  [IconName.USER2]: IconType.LINE,
  [IconName.STAR]: IconType.FILL,
  [IconName.PLAY_MINI]: IconType.FILL,
  [IconName.CAMERA_2]: IconType.LINE,
  [IconName.GLOBAL]: IconType.LINE,
  [IconName.PHONE]: IconType.FILL,
  [IconName.ZOOM_IN]: IconType.LINE,
  [IconName.BRIEFCASE_2]: IconType.LINE,
  [IconName.BANK_CARD]: IconType.LINE,
  [IconName.CHECKBOX_BLANK_CIRCLE]: IconType.LINE,
  [IconName.CHECKBOX_CIRCLE]: IconType.LINE,
  [IconName.ALERT]: IconType.LINE,
  [IconName.BRING_TO_FRONT]: IconType.ONLY_NAME,
  [IconName.SEND_TO_BACK]: IconType.ONLY_NAME,
  [IconName.LIST_CHECK]: IconType.ONLY_NAME,
  [IconName.PENCIL_RULER]: IconType.LINE,
  [IconName.ERROR_WARNING]: IconType.LINE,
  [IconName.HAND_COIN]: IconType.LINE,
  [IconName.WALLET]: IconType.LINE,
  [IconName.TIK_TOK]: IconType.LINE,
  [IconName.SAD]: IconType.LINE,
  [IconName.FILE_EDIT]: IconType.LINE,
}

export type IconProps = {
  color?: IconColor
  name: IconName
  type?: IconType
  size?: number
  className?: string
  bold?: boolean
}

const iconColorToStyle: { [key in IconColor]: string } = {
  [IconColor.RED]: styles.red,
  [IconColor.BLUE]: styles.blue,
  [IconColor.BLUE_LIGHT]: styles.blueLight,
  [IconColor.GREY]: styles.grey,
  [IconColor.GREY_LIGHT]: styles.greyLight,
  [IconColor.BURGUNDY]: styles.burgundy,
  [IconColor.WHITE]: styles.white,
  [IconColor.BLACK]: styles.black,
  [IconColor.GREEN]: styles.green,
  [IconColor.YELLOW]: styles.yellow,
}

type IProps = Omit<ComponentPropsWithRef<'i'>, 'color'>

export const Icon: FC<IProps & IconProps> = ({ name, type, color = IconColor.BLUE, size = 24, style, className, bold = false, ...props }) => {
  if (name === IconName.SHOWER) {
    return <Shower fillClassName={iconColorToStyle[color]} size={size} className={classNames(className, bold && styles.bold)} />
  }

  const defaultIconType = iconTypeToString[name]

  return (
    <i
      className={classNames(
        defaultIconType === IconType.ONLY_NAME ? `ri-${name}` : `ri-${name}-${type || defaultIconType}`,
        iconColorToStyle[color],
        className,
        bold && styles.bold,
        { [styles.clickable]: props.onClick }
      )}
      style={{ fontSize: size, ...style }}
      {...props}
    />
  )
}
