import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

export function usePersistLocaleCookie() {
  const { locale, defaultLocale } = useRouter()
  const [prevLocale, setPrevLocale] = useState<string>('')

  useEffect(() => {
    const localeToSave = navigator.language.split('-')[0]
    const localeCookie = Cookies.get('NEXT_LOCALE') || localeToSave

    if (prevLocale === '') setPrevLocale(localeCookie)

    if (localeCookie === 'es' && locale !== 'es') {
      const newPathName = window.location.toString().split('/')
      newPathName[2] += '/es'
      window.location.href = newPathName.join('/')
    }

    if (localeCookie === 'en' && locale === 'es') {
      window.location.href = window.location.toString().replace('/es', '')
    }

    if (prevLocale !== '' && prevLocale !== localeCookie) {
      window.location.reload()
    }

    if (document.cookie.indexOf('NEXT_LOCALE') !== -1) return

    const date = new Date()
    date.setTime(date.getTime() + 1000 * 3600 * 24 * 364)
    document.cookie = `NEXT_LOCALE=${localeToSave};expires=${date.toUTCString()};path=/`
  }, [locale, defaultLocale])
}
