import type { Admin } from 'model/Admin'
import type { User } from 'model/User'
import { getPublicRuntimeConfig } from 'services/config/publicRuntimeConfig'

declare global {
  interface Window {
    gtag: any
  }
}

export const { GA_TRACKING_ID } = getPublicRuntimeConfig()

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string, user: User | Admin | null | undefined) => {
  if (typeof window === 'undefined') return

  window.gtag?.('config', GA_TRACKING_ID, {
    page_path: url,
    user_id: user?.slug ?? undefined,
  })
}

type GoogleAnalyticsEventPayload = {
  action: string
  category: string
  label?: string
  value?: string | number
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const sendEventToGoogleAnalytics = ({ action, category, label, value }: GoogleAnalyticsEventPayload) => {
  if (typeof window === 'undefined') return

  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    value,
  })
}

export function sendRegisterEvent(url: Location) {
  const callback = function () {
    if (typeof url !== 'undefined') {
      window.location = url
    }
  }

  window.gtag('event', 'conversion', {
    send_to: 'AW-482667217/pUF0CIf604cYENHVk-YB',
    event_callback: callback,
  })

  return false
}
